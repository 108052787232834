<template>
  <div class="page-content">
    <Breadcrumb/>
    <SearchEngine
        v-if="isAdmin"
    />
    <router-view></router-view>
  </div>
</template>

<script>
import Breadcrumb from '../../templates/Breadcrumb/Breadcrumb.vue'
import SearchEngine from "../../coreComponents/SearchEngine/SearchEngine";
import {HEARTYSAN_USER_TYPES} from "@/staticData/staticVariables";
import {heartysanProductsSocketMixin} from "@/mixins/socketMixins/socketMixin";

export default {
  name: "HeartysanModuleModule",

  components: {
    SearchEngine,
    Breadcrumb,
  },

  mixins: [heartysanProductsSocketMixin],

  watch: {
    isSubUser() {
      return this.$router.push(this.$store.getters.GET_PATHS.notFound)
    },

    '$route'() {
      this.checkRoute()
    },

    getCurrentUser() {
      this.checkRoute()
      this.createProductSocket()
    },

  },

  data() {
    return {}
  },

  mounted() {
    if (this.isSubUser) {
      return this.$router.push(this.$store.getters.GET_PATHS.notFound)
    }

    if (this.getCurrentUser) {
      this.checkRoute()
      this.createProductSocket()
    }


  },

  methods: {

    createProductSocket() {
      let prepareSocket = this.prepareCreatePrivateSocketChannel('HeartysanProducts')
      if (prepareSocket.active) {
        this.createHeartysanProductsSocket(
            prepareSocket,
            (response) => {
              this.$store.commit('setHeartysanProductsEvents', response)
              this.changeProductEvent()
              // this.openNotify('chat', {chatLink: this.$store.getters.GET_PATHS.heartysanChat + '?chatId=' + response.data?.chat?.id + '&socketChat=1'})
            })
      }
    },

    changeProductEvent() {
      if (!this.isUserHeartySeller) return
      let event = this.$store.getters.getHeartysanProductsEvents
      let product = event.data.product
      console.log(2222);
      console.log(event.data.product);
      if(this.$store.getters.getHeartysanProducts.length > 0 && this._.find(this.$store.getters.getHeartysanProducts, {id: product.id})) {

        let indexProduct = this._.findIndex(this.$store.getters.getHeartysanProducts, {id: product.id})
        let products = this.$store.getters.getHeartysanProducts
        delete products[indexProduct]
        this.$store.commit('setHeartysanProducts', {heartysanProducts: products})
        products[indexProduct] = event.data.product
        this.$store.commit('setHeartysanProducts', {heartysanProducts: products})

      } else if(event.data.event.code === 1010) {

        let products = this.$store.getters.getHeartysanProducts
        products.unshift(event.data.product)
        // products[0].enable = true

        if(products.length > 25) {
          products.pop()
        }

        this.$store.commit('setHeartysanProducts', {heartysanProducts: products})

      }
    },


    checkRoute() {
      if (this.isAdmin) return
      if (!this.getCurrentUser) return

      let route = this.$route.fullPath
      let userHeartisanType = this.getCurrentUser?.hearty_user_type?.id

      if (!userHeartisanType) {
        if (route.indexOf(this.$store.getters.GET_PATHS.heartysanChoice) === -1) {
          return this.$router.push(this.$store.getters.GET_PATHS.heartysanChoice)
        }
        return
      }

      switch (userHeartisanType) {
        case HEARTYSAN_USER_TYPES.HEARTYSAN_MANUFACTURER.id:
          this.checkManufacturerUser(route)
          break
        case HEARTYSAN_USER_TYPES.HEARTYSAN_SELLER.id:
          this.checkSellerUser(route)
          break
        default:
          return this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }

    },

    checkManufacturerUser(route) {
      let routes = [
        this.$store.getters.GET_PATHS.heartysanSellerListings,
        this.$store.getters.GET_PATHS.heartysanSellerListingsShow,
      ]
      let flag = false
      routes.map(item => {
        if (route.indexOf(item) > -1 && !flag) {
          flag = true
          return this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      })
    },

    checkSellerUser(route) {
      let routes = [
        this.$store.getters.GET_PATHS.heartysanManufacturerListings,
        this.$store.getters.GET_PATHS.heartysanManufacturerListingsShow,
        this.$store.getters.GET_PATHS.heartysanListings,
        this.$store.getters.GET_PATHS.heartysanListingsCreate,
        this.$store.getters.GET_PATHS.heartysanListingsEdit,
      ]
      let flag = false
      routes.map(item => {
        if (route.indexOf(item) > -1 && !flag) {
          flag = true
          return this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      })
    },

  },

}

</script>

<style lang="scss">

</style>
